import React from 'react';
import Spinner from '../components/Dialogs/Spinner';

function SuspenseContent() {
  return (
    <div className='w-full h-screen flex justify-center items-center text-gray-300 dark:text-gray-200 bg-base-100'>
      <Spinner />
    </div>
  );
}

export default SuspenseContent;
