import React, { lazy, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { themeChange } from 'theme-change';

const Layout = lazy(() => import('./containers/Layout'));
const Landing = lazy(() => import('./pages/Landing'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Register = lazy(() => import('./pages/Register'));

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/register' element={<Register />} />
          <Route path='/' element={<Landing />} />

          <Route path='/app/*' element={<Layout />} />

          <Route
            path='*'
            element={
              <Navigate
                to={
                  localStorage.getItem('access-token')
                    ? '/app/dashboard'
                    : '/login'
                }
                replace
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
